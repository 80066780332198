export function equals<A, B>(obj1: A | undefined | null, obj2: B | undefined | null, ...keys: string[]): boolean {
  if (obj1 === obj2) {
    return true // Both are null, undefined, or the exact same reference
  }

  // eslint-disable-next-line eqeqeq
  if (obj1 == null || obj2 == null) {
    return false // One is null/undefined and the other is not
  }

  let keyMappings: string[] = []
  if (keys.length) {
    keyMappings = keys
  } else {
    keyMappings = Object.keys(obj1).map(k => k)
    if (keyMappings.length !== Object.keys(obj2).length) {
      return false
    }
  }

  return keyMappings.every(k => {
    const p1 = obj1[k]
    const p2 = obj2[k]

    if (p1 === Object(p1)) {
      return equals(p1, p2)
    }

    return p1 === p2
  })
}
